import React from "react";
import { Dialog } from "onedash-dialog";

const SchutzHygieneregeln = ({ poolData, selectedPool }: { poolData: PoolData; selectedPool: SwimmingPool }) => {
	const [hygieneOpen, updateHygiene] = React.useState(false);
	const [erweitertOpen, updateErweitert] = React.useState(false);

	const openPrivacy = (e: any) => {
		e.preventDefault();
		updateHygiene(true);
	};
	const openErweitert = (e: any) => {
		e.preventDefault();
		updateErweitert(true);
	};

	const documents = (poolData.files ?? []).concat(selectedPool.files ?? []);
	return (
		<div className="privacy-text">
			Hiermit bestätigen Sie, dass Sie die allgemeinen{" "}
			<a href="#" onClick={openPrivacy}>
				Schutz- und Hygieneregeln
			</a>{" "}
			{documents.length > 0 && (
				<>
					und die{" "}
					<a href="#" onClick={openErweitert}>
						ergänzenden Regelungen
					</a>
				</>
			)}{" "}
			gelesen und verstanden haben.
			<Dialog isOpen={erweitertOpen} closeBtn={<>+</>} onClose={() => updateErweitert(false)} title="Ergänzende Regelungen">
				<ul>
					{documents.map((doc) => (
						<li key={doc.url}>
							<a target="_blank" rel="noreferrer" href={doc.url}>
								{doc.fileName}
							</a>
						</li>
					))}
				</ul>
			</Dialog>
			<Dialog closeBtn={<>+</>} title="Datenschutz&shy;erklärung" isOpen={hygieneOpen} onClose={() => updateHygiene(false)}>
				<p>
					Liebe Besucherinnen und Besucher der hier angebotenen Einrichtungen (Testzentrum, Schwimmbad, Sauna, Barfußpfad etc.),{" "}
				</p>
				<p>
					in der aktuellen Situation gelten für Ihren Besuch in den hier gelisteten Einrichtungen besondere Regelungen zur
					Vermeidung einer Infektion mit dem Coronavirus SARS-CoV-2 (im Folgenden: Coronavirus) Diese Regelungen dienen dem Schutz
					Ihrer Gesundheit und der Mitarbeiter*innen und damit der Vermeidung von Ansteckungen. Uns ist bewusst, dass der Besuch
					der Einrichtungen für Sie mit mehr Auflagen und Einschränkungen verbunden ist als vor der Corona-Pandemie.
				</p>
				<p>
					Da die Anzahl der Besucher begrenzt ist und um Warteschlagen zu vermeiden, müssen Sie sich vor dem Besuch online
					registrieren. Mit der ausgedruckten oder digitalen Reservierungsbestätigung können Sie den Eingangsbereich der
					Einrichtung betreten und nachträglich eine Eintrittskarte erwerben.
				</p>
				<h3>Es gelten: </h3>
				<ul>
					<li>Maskenpflicht in bestimmten Bereichen</li>
					<li>Begrenzung der Anzahl von Besuchern</li>
					<li>Begrenzung der Nutzungszeiten</li>
					<li>Weitere behördliche Vorgaben </li>
				</ul>
				<p>
					Wir weisen Sie darauf hin, dass man trotz aller Bemühungen nicht ausschließen kann, dass sich Besucher mit dem
					Coronavirus infizieren (auch: Corona-Infektion). Damit in einem solchen Fall schnellstmöglich nachvollzogen werden kann,
					mit welchen Personen die oder der Infizierte während des Besuchs unserer Einrichtungen möglicherweise in Kontakt
					gekommen ist, ist bei der Online-Registrierung die Angabe Ihres Namens und Ihrer Email - Adresse sowie ggf. weiterer,
					nach aktuellen Bestimmungen oder Anordnungen geforderten, Kontaktdaten erforderlich.
				</p>
				<p>
					Außerdem sind Einrichtungen wie z.B. Schwimmbäder und Saunen ggf. verpflichtet, diese Daten sowie das Besuchsdatum und
					Zeitfenster zu dokumentieren und für den rechtlich geforderten Zeitraum zu speichern. Nur wer mit der Dokumentation
					seiner Daten, soweit diese rechtlich gefordert wird, einverstanden ist, darf die hier gelisteten Einrichtungen nutzen.
					Im Übrigen behalten sich die Einrichtungen weiterhin vor, den Zutritt von der Vorlage eines gültigen Lichtbildausweise
					abhängig zu machen.
				</p>

				<p>
					Selbstverständlich werden alle Möglichkeiten ausgeschöpft, um die Gefahr einer Infektion mit dem Coronavirus für unsere
					Gäste so gering wie möglich zu halten. Um dieses Ziel zur erreichen, ist es aber zwingend erforderlich, dass Sie Ihrer
					Eigenverantwortung – gegenüber sich selbst und anderen – durch die Einhaltung der Regelungen der Ergänzung der
					Hausordung nachkommen, auch ohne, dass Sie das Personal ständig darauf hinweisen muss.
				</p>

				<h3>Bitte beachten Sie während Ihres Besuchs: </h3>
				<ul>
					<li>
						Personen mit Fieber, Symptomen einer Atemwegserkrankung, einer bekannten/nachgewiesenen Corona-Infektion oder einem
						Verdacht darauf ist der Zutritt nicht gestattet!
					</li>
					<li>
						In allen geschlossenen Räumen, insbesondere den Eingangsbereichen, sowie in den ausgewiesenen Bereichen gilt
						Maskenpflicht. Beachten Sie die Hinweisschilder! Wenn Sie die Maske ablegen, bewahren Sie sie bitte bei Ihren
						persönlichen Sachen auf. Legen Sie die Maske bitte nicht einfach irgendwo ab. Damit gefährden Sie sich und andere!
					</li>
					<li>
						Nach dem Betreten der Einrichtung kann es erfoderlich sein, dass die Hände gewaschen oder desinfiziert werden
						müssen!{" "}
					</li>

					<li>
						Umkleiden und WC-Bereiche dürfen nur bis zu der jeweils vorgegebenen maximalen Anzahl von Personen betreten werden.
						Beachten Sie die Ausschilderungen!
					</li>

					<li>
						Halten Sie in allen Räumen und Schwimm- und Badebecken den gebotenen Abstand. Beachten Sie überall die
						Abstandsregelungen und Abstandsmarkierungen!
					</li>

					<li>Vermeiden Sie enge Begegnungen und nutzen Sie Möglichkeiten zum Ausweichen! Halten Sie sich an Wegeregelungen!</li>
					<li>Vermeiden Sie Menschenansammlungen und Gruppenbildungen! </li>
				</ul>

				<h3>Bitte beachten Sie zusätzlich in Schwimmbädern: </h3>
				<ul>
					<li>In Schwimmbecken gibt es Zugangsbeschränkungen, beachten Sie die Beschilderungen und Hinweise des Personals!</li>

					<li>Betreten Sie den Beckenumgang nur unmittelbar vor der Nutzung z.B. der Becken, Sprunganlage oder Wasserrutsche!</li>

					<li>Verlassen Sie bitte nach dem Schwimmen unverzüglich die Wasserflächen und deren unmittelbares Umfeld! </li>
				</ul>
				<p>Wir bitten um Ihr Verständnis für diese Maßnahmen und Ihre Mithilfe bei deren Umsetzung.</p>
			</Dialog>
		</div>
	);
};

export default SchutzHygieneregeln;
