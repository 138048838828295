import React from "react";
import Privacy from "./Privacy";
import { Dialog } from "onedash-dialog";

interface PrivacyTextProps {
	onClick?: () => void;
}

const PrivacyText = ({ onClick: onClickOverride }: PrivacyTextProps) => {
	const [isOpen, update] = React.useState(false);

	const openPrivacy = (e: any) => {
		e.preventDefault();
		update(true);
	};

	const onClick = (e: any) => {
		if (onClickOverride && e.target.classList.contains("privacy-text")) onClickOverride();
	};

	return (
		<div className="privacy-text" onClick={onClick}>
			Hiermit bestätigen Sie, dass Sie unsere{" "}
			<a href="/privacy" onClick={openPrivacy}>
				Datenschutzerklärung
			</a>{" "}
			gelesen und verstanden haben.
			<Dialog closeBtn={<>+</>} title="Datenschutz&shy;erklärung" isOpen={isOpen} onClose={() => update(false)}>
				<Privacy inline />
			</Dialog>
		</div>
	);
};

export default PrivacyText;
