import React, { Component } from "react";
import Lottie, { Options } from "react-lottie";

interface AnimationComponentProps {
	animationData?: any;
	options?: AnimationOptions;
	width?: number | string;
	height?: number | string;
}
interface AnimationOptions {
	loop?: boolean | number;
	autoplay?: boolean;
	rendererSettings?: {
		preserveAspectRatio?: string;
		context?: any;
		scaleMode?: any;
		clearCanvas?: boolean;
		progressiveLoad?: boolean;
		hideOnTransparent?: boolean;
		className?: string;
	};
}

export default class AnimationComponent extends Component<AnimationComponentProps> {
	state = {
		isStopped: false,
		isPaused: false,
		options: {
			loop: true,
			autoplay: true,
		} as Options,
	};

	render() {
		return (
			<div>
				<Lottie
					options={{ ...this.state.options, ...this.props.options, animationData: this.props.animationData.default }}
					height={this.props.height}
					width={this.props.width ? this.props.width : "100%"}
					isStopped={this.state.isStopped}
					isPaused={this.state.isPaused}
				/>
			</div>
		);
	}
}
