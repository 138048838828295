import React, { Component } from "react";
import { createStore } from "redux";
import "./App.scss";
import { Switch, Route, Redirect } from "react-router-dom";
import reducer from "../../Utils/Redux/Reducer";
import moment from "moment";
import "moment/locale/de";
import VG_LIST from "../../Resources/VG_LIST";
import Action from "../../Utils/Redux/Action";
import VGCorona from "../VGCorona/VGCorona";
import Backend from "../../Utils/Backend/Backend";
import Home from "../Home/Home";
import ENV from "../../Utils/ENV";
import ServerStatus from "../../Utils/ServerStatus";
import BrowserUtils from "../../Utils/BrowserUtils";
import VGTicketOverview from "../VGTicketOverview/VGTicketOverview";
import Spinner from "../../Components/Spinner/Spinner";
import { DialogUtils } from "onedash-dialog";

moment.locale("de");

declare global {
	interface Window {
		reduxStore: {
			subscribe: (cb: () => void) => void;
			getState: () => ReducerType;
			dispatch: (arg: any) => void;
		};
		_env: any;
	}
}
interface AppProps {}

class App extends Component<AppProps> {
	state = {
		theme: "light" as "light" | "dark",
		isLoaded: false,
	};
	constructor(props: AppProps) {
		super(props);
		new ServerStatus(ENV.serverStatus.url, ENV.serverStatus.id);
		window.reduxStore = createStore(reducer);
	}

	componentDidMount() {
		DialogUtils.registerHeightHelper();

		const store = window.reduxStore;
		this.selectVG();
		Backend.init();

		store.subscribe(() => {
			const data = window.reduxStore.getState();
			if (data.themeMode) {
				this.setState({ theme: data.themeMode });
			}
		});
	}

	selectVG = () => {
		// Used for automated (cypress tests) => No subdomain is used
		if (BrowserUtils.getParam("test") === "true") {
			const appID = BrowserUtils.getParam("appID");
			const vg = VG_LIST.find((x) => x.appID === appID);
			if (vg) {
				Action.selectVG(vg);
				this.setState({
					isLoaded: true,
				});
				return;
			}
		}

		const subdomain = window.location.host.split(".")[0];
		let vg = VG_LIST.find((x) => x.subdomain === subdomain);
		if (vg) {
			Action.selectVG(vg);
			this.setState({
				isLoaded: true,
			});
			return;
		}

		// Select default
		vg = VG_LIST.find((x) => x.default === true);
		if (vg) {
			console.warn("The selected subdomain does not exist. The default one will be used");
			const baseURL = ENV.default.baseURL;
			if (baseURL === undefined) return;
			const start = baseURL.indexOf("://") + 3;
			const newURL = baseURL.substr(0, start) + `${vg.subdomain}.` + baseURL.substr(start, baseURL.length);
			window.location.href = newURL;
			return;
		}
	};

	render() {
		const { isLoaded } = this.state;
		return (
			<>
				{isLoaded && (
					<>
						<Home />
						<Switch>
							<Route path="/corona-reservation" component={VGCorona} />
							<Route path="/corona-tickets" component={VGTicketOverview} />
							<Redirect to={{ pathname: "/corona-reservation", search: window.location.search }} />
						</Switch>
					</>
				)}
				{!isLoaded && <Spinner defaultVisible fullPage />}
			</>
		);
	}
}

export default App;
