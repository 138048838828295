import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import BrowserHistory from "./Utils/BrowserHistory";
import App from "./Pages/App/App";
import * as serviceWorker from "./serviceWorker";
import "./Styles/dialog.sass";
import "./Styles/input.sass";
import "./Styles/default.scss";
import "./Styles/default.sass";
import { setLocaleMessages as setFormLocaleMessages, FormLocales } from "onedash-react-input-form";
import { PromptProvider, setLocaleMessages as setDialogLocaleMessages, DialogLocales } from "onedash-dialog";

// Set Tool locale to German
setFormLocaleMessages(FormLocales.DE);

// Set dialog locale to German
setDialogLocaleMessages(DialogLocales.DE);

ReactDOM.render(
	<Router history={BrowserHistory as any}>
		<PromptProvider>
			<App />
		</PromptProvider>
	</Router>,
	document.getElementById("root")
);
serviceWorker.unregister();
