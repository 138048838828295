import React from "react";
export interface PrivacyProps {
	inline?: boolean;
}

const Privacy = ({ inline }: PrivacyProps) => {
	return (
		<div className={inline === true ? "privacy about-text" : "privacy about-text standalone"}>
			{!inline && <h2>Datenschutz&shy;erklärung</h2>}
			<h3>Kurzfassung</h3>
			<p>
				Wir verarbeiten personenbezogene Daten der Nutzer*innen dieser Anwendung nur unter Einhaltung der einschlägigen
				Datenschutzbestimmungen. Das bedeutet: Diese Daten werden grundsätzlich nur bei Vorliegen einer gesetzlichen Erlaubnis
				verarbeitet.
			</p>
			<p>
				Jegliche Daten, die erhoben werden, werden <span className="bold">nicht</span> an Drittanbieter weitergegeben. Sie dienen
				nur der jewails explizit angegebenen Funktion.
			</p>
			<p>Wir treffen Sicherheitsmaßnahmen, um Ihre Daten zu schützen.</p>
			<p>
				Damit Sie diese Anwendung überhaupt ansehen können, wird Ihre IP-Adresse vom Webserver für die Dauer des Besuchs
				verarbeitet. Der Webserver selbst wird von dem deutschen Unternehmen Netcup in Deutschland bereitgestellt.
			</p>
			<p>
				Diese Seite verwendet keine Cookies oder ähnliches zum Tracken der Nutzerdaten. Wir verwenden den sog.
				&quot;LocalStorage&quot; um persönliche Einstellungen und Reservierungsdaten zwischenzuspeichern.
			</p>
			<h3>Datenschutz</h3>
			<p>
				Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer personenbezogenen Daten
				(nachfolgend auch kurz als “Daten“ bezeichnet) wir zu welchen Zwecken und in welchem Umfang im Rahmen der Bereitstellung
				unserer Applikation verarbeiten.
			</p>
			<p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
			<p>
				Die Betreiber dieser Seite nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
				vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
			</p>
			<p>
				Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten
				personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich,
				stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.
			</p>
			<p>
				Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken
				aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
			</p>
			<h4>Arten der verarbeiteten Daten</h4>
			<ul>
				<li>Bestandsdaten (z.B. Personen-Stammdaten, Namen oder Adressen)</li>
				<li>Kontaktdaten (z.B. E-Mail, Telefonnummern)</li>
				<li>Inhaltsdaten (z.B. Texteingaben, Fotografien)</li>
				<li>Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen)</li>
			</ul>
			<h3>Geltungsbereich</h3>
			<p>
				Diese Datenschutzerklärung gilt für das Internet- und App - Angebot
				<a href="https://oeffentliche-verwaltungen.de">https://oeffentliche-verwaltungen.de</a> und aller Subdomains des
				Unternehmens OneDash. und die dort angebotenen eigenen Inhalte. Für Inhalte anderer Anbieter, auf die z.B. über Links
				verwiesen wird, gelten die dortigen Bestimmungen. Insbesondere sind diese gemäß § 7 Abs. 1 Telemediengesetz (TMG) für eigene
				Inhalte verantwortlich.
			</p>
			<h3>Verantwortlicher</h3>
			<p>
				Christian Bußhoff, <br />
				Burgstraße 19
				<br />
				54470 Bernkastel - Kues
			</p>
			<p>
				E-Mail: <a href="mailto:datenschutz@onedash.de">datenschutz@onedash.de</a>
			</p>
			<h3>Kategorien betroffener Personen</h3>
			<ul>
				<li>Kommunikationspartner</li>
				<li>Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten)</li>
			</ul>
			<h3>Rechte der betroffenen Personen</h3>
			<p>
				Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf Auskunft über
				diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend den gesetzlichen Vorgaben.
			</p>
			<p>
				Sie haben entsprechend. den gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie betreffenden Daten oder die
				Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.
			</p>
			<p>
				Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht zu verlangen, dass betreffende Daten unverzüglich gelöscht
				werden, bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben eine Einschränkung der Verarbeitung der Daten zu verlangen.
			</p>
			<p>
				Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten, die Sie uns bereitgestellt haben nach Maßgabe der
				gesetzlichen Vorgaben zu erhalten und deren Übermittlung an andere Verantwortliche zu fordern.
			</p>
			<p>
				Sie haben ferner nach Maßgabe der gesetzlichen Vorgaben das Recht, eine Beschwerde bei der zuständigen Aufsichtsbehörde
				einzureichen.
			</p>
			<h3>Server Log Files</h3>
			<p>
				Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log Files, die Ihr Browser
				beim Zugriff auf unsere Seiten automatisch an uns übermittelt. Dies sind:
			</p>
			<ul>
				<li>Dateiname</li>
				<li>Datum und Uhrzeit der Anforderung</li>
				<li>übermittelte Datenmenge</li>
				<li>Browsertyp/ Browserversion</li>
				<li>verwendetes Betriebssystem</li>
				<li>Referrer URL</li>
				<li>Zugriffsstatus (übermittelt, nicht gefunden etc.)</li>
			</ul>
			<p>
				Diese Daten sind nicht bestimmten Personen zuordbar. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
				vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte für eine
				rechtswidrige Nutzung bekannt werden.
			</p>
			<h3>Cookies </h3>
			<p>
				Die Anwendung verwendet teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten
				keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine
				Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert. Die von uns verwendeten Cookies sind so
				genannte „Session-Cookies“. In einem Session-Cookie wird eine zufällig erzeugte eindeutige Identifikationsnummer abgelegt,
				eine sogenannte Session-ID. Außerdem enthält ein Cookie die Angabe über seine Herkunft und die Speicherfrist. Diese Cookies
				können keine anderen Daten speichern und werden nach Ende Ihres Besuchs automatisch gelöscht. Sie können Ihren Browser so
				einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von
				Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser
				aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein. Bei der Nutzung
				fremder Inhalte über unsere Webseite (z.B. im Fall einer Verlinkung) können gegebenenfalls Cookies von Drittanbietern zum
				Einsatz kommen, ohne dass wir Sie ausdrücklich darauf hinweisen können.
			</p>
			<h3>Lokaler Speicher (Local Storage)</h3>
			<p>
				Um Ihre persönlichen Einstellungen und Reservierungsdaten (Nicht personenbezogen) speichern zu können, verwenden wir den
				sog. &quot; Lokalen Speicher (Local Storage) &quot;. Dabei werden Einstellungen und Daten im Cache des Browsers gespeichert,
				die so lange der Browser cache nicht gelöscht werden bestehen bleiben. Auf die im Local Storage gespeicherten Daten können
				Dritte nicht zugreifen. Sie werden an Dritte nicht weitergegeben und auch nicht zu Werbezwecken verwendet.
			</p>
			<p>Es werden die folgenden Daten im LocalStorage hinterlegt:</p>
			<ul>
				<li>
					Anonymisierte Reservierungsdaten (Anzahl der Personen, Datum, Name der Einrichtung und Ticket Identifikationsnummer)
				</li>
			</ul>
			<h3>Sicherheitsmaßnahmen</h3>
			<p>
				Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung des Stands der Technik, der Implementierungskosten
				und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der unterschiedlichen
				Eintrittswahrscheinlichkeit und Schwere des Risikos für die Rechte und Freiheiten natürlicher Personen, geeignete technische
				und organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu gewährleisten. Zu den Maßnahmen gehören
				insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen
				Zugangs zu den Daten, als auch des sie betreffenden Zugriffs, der Eingabe, Weitergabe, der Sicherung der Verfügbarkeit und
				ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, Löschung von
				Daten und Reaktion auf Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten
				bereits bei der Entwicklung, bzw. Auswahl von Hardware, Software sowie Verfahren, entsprechend dem Prinzip des Datenschutzes
				durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.
			</p>
			<h3>Kontaktaufnahme</h3>
			<p>
				Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail ) werden die Angaben des Nutzers zur Bearbeitung der
				Kontaktanfrage und deren Abwicklung gem. Art. 6 Abs. 1 lit. b. (im Rahmen vertraglicher-/vorvertraglicher Beziehungen), Art.
				6 Abs. 1 lit. f. (andere Anfragen) DSGVO verarbeitet. Die Angaben der Nutzer können in einem
				Customer-Relationship-Management System (CRMS) gespeichert werden. Wir löschen die Anfragen, sofern diese nicht mehr
				erforderlich sind. Wir überprüfen die Erforderlichkeit alle zwei Jahre; Ferner gelten die gesetzlichen
				Archivierungspflichten.
			</p>
			<p>
				Ihre personenbezogenen Daten werden gemäß Art. 6 Abs. 1 b) DSGVO ausschließlich zur Abwicklung Ihrer Reservierungen sowie
				gemäß Art. 6 Abs. 1 f) DSGVO zur Wahrung unserer berechtigten Interessen genutzt.
			</p>
			<h3>Emailversand</h3>
			<p>Um die reservierten Tickets zustellen zu können, verwenden wir einen Dienstleister zum Versenden von E-Mails:</p>
			<h4>MailGun</h4>
			<p>
				Der Versand der E-Mails über das Registrierungsportal erfolgt mittels „Mailgun“, einer E-Mailversandplattform des
				US-Anbieters Mailgun Technologies, Inc., 535 Mission St., San Francisco, CA 94105.
			</p>
			<p>
				Der Anbieter ist als Privacy-Shield-zertifziert ausgewiesen , was die Einhaltung der EU DSGVO sowie aller sonstigen in den
				Mitgliedstaaten der EU geltenden Datenschutzgesetze bzw. Bestimmungen mit datenschutzrechtlichem Charakter garantiert (
				<a href="https://www.privacyshield.gov/participant?id=a2zt0000000PCbmAAG">Zur Zertifizierung</a>). Weitere Informationen zur
				Datenverarbeitung durch den Anbieter finden Nutzer unter{" "}
				<a href="https://www.mailgun.com/privacy-policy">https://www.mailgun.com/privacy-policy</a>.
			</p>
			<h3>Google Fonts</h3>
			<p>
				Wir binden die Schriftarten (Google Fonts) des Anbieters Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
				Irland, ein. Nach Angaben von Google werden die Daten der Nutzer allein zu Zwecken der Darstellung der Schriftarten im
				Browser der Nutzer verwendet. Die Einbindung erfolgt auf Grundlage unserer berechtigten Interessen an einer technisch
				sicheren, wartungsfreien und effizienten Nutzung von Schriftarten, deren einheitlicher Darstellung sowie Berücksichtigung
				möglicher lizenzrechtlicher Restriktionen für deren Einbindung. Datenschutzerklärung:{" "}
				<a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>.
			</p>
			<h3>Änderung und Aktualisierung der Datenschutzerklärung</h3>
			<p>
				Wir bitten Sie, sich regelmäßig über den Inhalt unserer Datenschutzerklärung zu informieren. Wir passen die
				Datenschutzerklärung an, sobald die Änderungen der von uns durchgeführten Datenverarbeitungen dies erforderlich machen. Wir
				informieren Sie, sobald durch die Änderungen eine Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine sonstige
				individuelle Benachrichtigung erforderlich wird.
			</p>
			<p>
				Sofern wir in dieser Datenschutzerklärung Adressen und Kontaktinformationen von Unternehmen und Organisationen angeben,
				bitten wir zu beachten, dass die Adressen sich über die Zeit ändern können und bitten die Angaben vor Kontaktaufnahme zu
				prüfen.
			</p>
			<h3>
				Ergänzende Datenschutzhinweise bezüglich der Corona Pandemie nach Artikel 13 und 14 Datenschutz&shy;grundverordnung (DSGVO)
			</h3>
			<h4>Name und Kontaktdaten der für die Datenverarbeitung verantwortlichen Stelle</h4>
			<p>
				OneDash.
				<br />
				Christian Bußhoff <br />
				Burgstraße 19
				<br />
				54470 Bernkastel - Kues
			</p>
			<h4>Kategorien von personenbezogenen Daten</h4>
			<p>
				Wenn Sie eines der auf dieser Platform angebotenen Schwimmbäder besuchen möchten, erheben wir die folgende Informationen von
				Ihnen:
			</p>
			<ul>
				<li>Name, Vorname </li>
				<li>Adresse</li>
				<li>Telefonnummer und E-Mail-Adresse</li>
				<li>Datum und Zeitpunkt des Beginnes und Ende des Besuchs</li>
			</ul>
			Sollten Sie mit der Erfassung dieser Daten nicht einverstanden sein, kann die jeweilige Einrichtung Ihnen keinen Eintritt
			ermöglichen.
			<h4>Zweck und Rechtsgrundlage der Verarbeitung</h4>
			<p>
				Die Datenerhebung erfolgt zum Zweck der Ermöglichung einer Kontaktpersonennachverfolgung im Zusammenhang mit Covid-19.
				Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 c) DSGVO i. V. m. CoronaBekämpfungsverordnung Rheinland-Pfalz in der
				jeweils gültigen Fassung
			</p>
			<p>
				Zusätzlich verwenden wir Ihre Email - Adresse um Sie zu benachrichtigen, falls Sie Ihre Reservierung nicht wahrnehmen
				können. Dies kann beispielsweise der Fall sein, wenn eine Einrichtung witterungsbedingt schließen muss.
			</p>
			<h4>Übermittlung von Daten an Dritte</h4>
			<p>
				Eine Übermittlung der o.g. Daten erfolgt nur an das jeweils zuständige Gesundheitsamt bzw. die jeweils zuständige
				öffentliche Stelle zu den o.g. Zwecken. Ihre Daten werden ausschließlich auf Grundlage der oben genannten Rechtsgrundlagen
				an Dritte weitergegeben. Sie werden ausdrücklich nicht für Werbe-, oder andere Zwecke verwendet.
			</p>
			<h4>Dauer der Speicherung</h4>
			<p>Die Daten werden vier Wochen ab dem Zeitpunkt Ihres Besuchs bei uns gespeichert. Anschließend werden sie vernichtet.</p>
			<h4>Ihre Betroffenenrechte</h4>
			<p> Als Betroffener gemäß DSGVO haben Sie folgende Rechte:</p>
			<ul>
				<li>Recht auf Auskunft</li>
				<li>Recht auf Berichtigung</li>
				<li>Recht auf löschung oder auf einschränkung der verarbeitung</li>
				<li>Recht auf Beschwerde bei einer Aufsichtsbehörde</li>
			</ul>
		</div>
	);
};

export default Privacy;
