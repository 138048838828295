import React, { Component } from "react";
import PACKAGE from "../../../package.json";
import VG_LIST from "../../Resources/VG_LIST";
import Backend from "../../Utils/Backend/Backend";
import CoronaVirus from "../../Resources/SVG/coronavirus.svg";
import Ticket from "../../Resources/SVG/validating-ticket.svg";
import Talk from "../../Resources/SVG/talk.svg";
import Contract from "../../Resources/SVG/contract.svg";
import PrivacyIcon from "../../Resources/SVG/privacy.svg";
import "./Home.scss";
import { Link } from "react-router-dom";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Privacy from "../../Components/Privacy";
import Impress from "../../Components/Impress";
import Contact, { ContactData } from "../../Components/Contact";
import { ValueLabelPair, NativeSelect } from "onedash-react-input-form";
import { Dialog } from "onedash-dialog";

interface HomeProps {}

class Home extends Component<HomeProps> {
	state = {
		open: false,
		contactData: undefined as undefined | ContactData,
		showPrivacy: false,
		showImpress: false,
		showContact: false,
	};
	changeVG = (vg: Verbandsgemeinde) => {
		const location = window.location.href;
		const dot = location.indexOf(".");

		const start = location.indexOf("://") + 3;
		const newURL = location.substr(0, start) + vg.subdomain + location.substr(dot, location.length);
		window.location.href = newURL;
	};

	toggleHome = () => {
		const home = document.querySelector(".home");
		if (!home) return;
		// IOS disables scrolling in impress and privacy dialog => So dont disable it at all

		if (this.state.open === false) {
			disableBodyScroll(home);
		} else {
			enableBodyScroll(home);
		}
		this.setState({
			open: !this.state.open,
		});
	};

	loadContact = () => {
		this.showPopup("Contact");
		Backend.get("/appSettings/appData").then((appData: any) => {
			this.setState({
				contactData: {
					name: appData.name,
					street: appData.contact.street,
					plz: appData.contact.plz,
					place: appData.contact.place,
					phone: appData.contact.phone,
					fax: appData.contact.fax,
					email: appData.contact.email,
				},
			});
		});
	};
	showPopup = (popup: "Privacy" | "Contact" | "Impress") => {
		/*const home = document.querySelector(".home");
		if (!home) return;
		disableBodyScroll(home);*/
		this.setState({ [`show${popup}`]: true });
	};
	hidePopup = (popup: "Privacy" | "Contact" | "Impress") => {
		/*const home = document.querySelector(".home");
		if (!home) return;
		enableBodyScroll(home);*/
		this.setState({ [`show${popup}`]: false });
	};

	render() {
		const { open, contactData, showContact, showImpress, showPrivacy } = this.state;
		const selectOptions: ValueLabelPair[] = [];
		VG_LIST.forEach((vg) => {
			selectOptions.push({
				label: vg.name,
				value: vg,
			});
		});
		const selectedVG = VG_LIST.find((x) => x.appID === Backend.getAppID());

		return (
			<>
				<button
					aria-label="Öffnet bzw. schließt das Home - Menü"
					onClick={this.toggleHome}
					className={open === true ? "home-btn open" : "home-btn"}>
					<i className="im im-menu"></i>
				</button>
				<div aria-hidden={!open} className={open === true ? "home open" : "home"}>
					<div className="wrapper" style={{ minHeight: window.innerHeight - 130 }}>
						<div>
							<div className="vg-select">
								<h1>Verbandsgemeinde</h1>
								<div className="select">
									<NativeSelect
										required
										settings={{ requiredNotVisible: true }}
										onChange={this.changeVG}
										value={selectedVG}
										name="vg"
										options={selectOptions}
										placeholder="Wählen Sie die Verbandsgemeinde"
									/>
									<i className="im im-angle-down"></i>
								</div>
							</div>
							<div className="app-list">
								<AppTileLink
									toggleHome={this.toggleHome}
									to="/corona-reservation"
									img={CoronaVirus}
									title="Reservierung"
									description="Covid - 19 Reservierung"
								/>
								{localStorage.getItem("tickets") && JSON.parse(localStorage.getItem("tickets") ?? "[]").length > 0 && (
									<AppTileLink
										toggleHome={this.toggleHome}
										to="/corona-tickets"
										img={Ticket}
										title="Ihr Tickets"
										description="Ticketübersicht Covid - 19"
									/>
								)}
								<AppTile img={Talk} onClick={this.loadContact} title="Kontakt" description="Kontakt-informationen" />
								<AppTile
									img={Contract}
									onClick={() => this.showPopup("Impress")}
									title="Impressum"
									description="Über diese Seite"
								/>
								<AppTile
									img={PrivacyIcon}
									onClick={() => this.showPopup("Privacy")}
									title="Datenschutz-erklärung"
									description="Was mit Ihren Daten geschieht"
								/>
							</div>
						</div>
						<div className="footer">
							<h3>Version {PACKAGE.version}</h3>
							<a href="https://onedash.de" className="onedash-logo">
								<span>One</span>
								<span>Dash</span>
								<span>.</span>
							</a>
						</div>
					</div>
				</div>

				<Dialog closeBtn={<>+</>} onClose={() => this.hidePopup("Privacy")} title="Datenschutz&shy;erklärung" isOpen={showPrivacy}>
					<Privacy inline />
				</Dialog>
				<Dialog closeBtn={<>+</>} onClose={() => this.hidePopup("Impress")} title="Impressum" isOpen={showImpress}>
					<Impress inline />
				</Dialog>
				<Dialog closeBtn={<>+</>} onClose={() => this.hidePopup("Contact")} title="Kontakt&shy;informationen" isOpen={showContact}>
					<Contact contact={contactData} />
				</Dialog>
			</>
		);
	}
}

interface AppTileProps {
	title: string;
	description: string;
	img: string;
	onClick?: () => void;
}
const AppTile = ({ title, description, img, onClick }: AppTileProps) => {
	return (
		<button onClick={onClick} className="app-tile">
			<div>
				<img src={img} alt={title} />
				<h2>{title}</h2>
				<p className="description">{description}</p>
			</div>
		</button>
	);
};

interface AppTileLinkProps {
	title: string;
	description: string;
	img: string;
	to: string;
	toggleHome: () => void;
}
const AppTileLink = ({ title, description, img, to, toggleHome }: AppTileLinkProps) => {
	return (
		<Link to={to} onClick={toggleHome} className="app-tile">
			<div>
				<img src={img} alt={title} />
				<h2>{title}</h2>
				<p className="description">{description}</p>
			</div>
		</Link>
	);
};

export default Home;
