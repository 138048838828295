import { Form, Input } from "onedash-react-input-form";
import React, { Component } from "react";

interface VGCoronaDataEntryProps {
	index: number;
	maxPeople: number;
	onChange: (valid: boolean) => void;
}
interface Household {
	num: number;
	ref: React.RefObject<Form>;
	valid: boolean;
}
const houseHoldText = ["Erster", "Zweiter", "Dritter", "Vierter"];

class VGCoronaDataEntry extends Component<VGCoronaDataEntryProps> {
	mailRepeat = React.createRef<Input>();

	state = {
		mail: undefined as undefined | string,
		households: [
			{
				num: 0,
				ref: React.createRef<Form>(),
				valid: false,
			},
		] as Household[],
		showMailInfo: false,
	};

	public getHouseholds = () => {
		const households = this.state.households.map((household) => {
			if (household.ref.current) {
				const data = household.ref.current.getData();
				const members = Object.keys(data)
					.filter((x) => x.indexOf("member") !== -1 && data[x] !== undefined)
					.map((x) => data[x]);
				return {
					name: `${data.firstName} ${data.lastName}`,
					email: data.email,
					street: data.address,
					plz: data.plz,
					place: data.city,
					phone: data.phone,
					members,
				};
			}
		});
		return households;
	};

	addHousehold = () => {
		const households = this.state.households;
		households.push({
			num: 0,
			ref: React.createRef<Form>(),
			valid: false,
		});
		this.setState({ households }, this.validate);
	};
	deleteHousehold = (index: number) => {
		const households = this.state.households;
		households.splice(index, 1);
		this.setState({ households }, this.validate);
	};
	addMember = (index: number) => {
		const households = this.state.households;

		households[index].num++;
		this.setState({ households });
	};
	removeMember = (index: number) => {
		const households = this.state.households;

		households[index].num--;
		this.setState({ households });
	};

	validate = () => {
		let globalValid = true;
		this.state.households.forEach((hh) => {
			if (!hh.valid) globalValid = false;
		});
		this.props.onChange(globalValid);
	};
	onChange = (h: Household, valid: boolean) => {
		const households = this.state.households;
		h.valid = valid;
		this.setState({ households }, this.validate);
	};

	checkMailCompany = (mail?: string | null) => {
		if (!mail) return;
		this.setState(
			{
				mail,
			},
			this.revalidateEmail
		);

		const regex = new RegExp(/(.)*@gmx\.(.)*|(.)*@web\.(.)*/);
		let showMailInfo = false;
		if (regex.test(mail) === true) {
			showMailInfo = true;
		}
		this.setState({ showMailInfo });
	};
	revalidateEmail = () => {
		const households = this.state.households;
		if (households.length === 0) return;
		const mailRef = households[0].ref.current?.getRef("email-repeat");
		mailRef?.ref.validate();
	};

	checkMailRepeat = (repeatedMail?: string | null) => {
		return repeatedMail?.toLowerCase().trim() === this.state.mail?.toLowerCase().trim();
	};
	render() {
		const { index, maxPeople } = this.props;
		const { households } = this.state;
		const members = households.reduce((x, y) => x + y.num, 0) + households.length;
		return (
			<div className="data-entry">
				<h1>{index}. Geben Sie Ihre Daten an</h1>

				{households.map((x, i) => (
					<Form onChange={(_data, _form, valid) => this.onChange(x, valid)} ref={x.ref} className="household" key={i}>
						{i !== 0 && <i className="im im-minus-circle delete-household" onClick={() => this.deleteHousehold(i)}></i>}
						<h2>{houseHoldText[i]} Haushalt</h2>
						<Input required label="Vorname" autoComplete="given-name" name="firstName" />
						<Input required label="Nachname" autoComplete="family-name" name="lastName" />
						<Input required type="email" onChange={this.checkMailCompany} label="Email" autoComplete="email" name="email" />
						{i === 0 && (
							<Input
								required
								type="email"
								label="Email wiederholen"
								autoComplete="email"
								name="email-repeat"
								onValidate={this.checkMailRepeat}
							/>
						)}
						{
							<div className={`info email-info ${this.state.showMailInfo ? "visible" : ""}`}>
								<i className="im im-info"></i>
								<div className="text">
									Aufgrund von technischen Besonderheiten dauert der E-Mailversand zu GMX und Web länger. Es ist mit einer
									Verzögerung von bis zu einer Stunde zu rechnen.
								</div>
							</div>
						}
						<Input required type="tel" label="Telefon" autoComplete="tel" name="phone" />
						<Input required label="Adresse" autoComplete="street-address" name="address" />
						<Input required label="Postleitzahl" autoComplete="postal-code" name="plz" />
						<Input required label="Ort" autoComplete="address-level2" name="city" />
						{maxPeople > 1 && (
							<div className="info">
								<i className="im im-info"></i>
								<div className="text">
									Bitte geben Sie hier jede weitere Person an, die Sie aus diesem Haushalt begleiten wird.{" "}
								</div>
							</div>
						)}
						<div className="members">
							{[...Array(x.num)].map((_x, ii) => (
								<div key={ii} className={ii === x.num - 1 ? "member-input last-item" : "member-input"}>
									<Input
										label={`${ii + 2}. Anwohner`}
										placeholder="Vor- und Nachname"
										autoComplete="off"
										name={`member-${ii}`}
									/>
									{ii === x.num - 1 && <i className="im im-minus-circle" onClick={() => this.removeMember(i)}></i>}
								</div>
							))}
							{maxPeople > 1 && (
								<div
									onClick={() => this.addMember(i)}
									className={members >= maxPeople ? "disabled member-input new-member" : "member-input new-member"}>
									<p>Weiterer Anwohner</p>
									<i className="im im-plus-circle"></i>
								</div>
							)}
						</div>
					</Form>
				))}
				{maxPeople > 1 && (
					<button className="primary-btn" disabled={members >= maxPeople} onClick={this.addHousehold}>
						Weiteren Haushalt hinzufügen
					</button>
				)}
			</div>
		);
	}
}

export default VGCoronaDataEntry;
