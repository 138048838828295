import React, { Component } from "react";
import Spinner from "./Spinner/Spinner";

interface ContactProps {
	contact?: ContactData;
}
export interface ContactData {
	name: string;
	street: string;
	plz: string;
	place: string;
	phone: string;
	fax?: string;
	email: string;
}
class Contact extends Component<ContactProps> {
	render() {
		const { contact } = this.props;
		return (
			<div className="about-text">
				{contact && (
					<>
						<h3>{contact.name}</h3>

						<p>
							{contact.street}
							<br />
							{contact.plz} {contact.place}
							<br />
						</p>
						<p>
							<span className="bold">Telefon:</span> <a href={`tel:${contact.phone}`}>{contact.phone}</a>
							<br />
							{contact.fax && (
								<>
									<span className="bold">Fax:</span> {contact.fax}
								</>
							)}
							<br />
							<span className="bold">E-Mail Adresse:</span> <a href={`mailto:${contact.email}`}>{contact.email}</a>
						</p>
					</>
				)}
				{!contact && <Spinner />}
			</div>
		);
	}
}

export default Contact;
