import AXIOS from "axios";
import ENV from "../ENV";

export default class Backend {
	private static appID: string | undefined;
	private static backendDataServer = ENV.default.apiURL;

	public static get = async <T extends any>(path: string, app = true) => {
		return Backend.sendRequest(path, app, undefined, "get") as Promise<T>;
	};
	public static post = async <T extends any>(path: string, data?: any, app = true) => {
		return Backend.sendRequest(path, app, data, "post") as Promise<T>;
	};
	public static put = async <T extends undefined>(path: string, data: any, app = true) => {
		return Backend.sendRequest(path, app, data, "put") as Promise<T>;
	};

	public static setAppID = (appID: string | undefined) => {
		Backend.appID = appID;
	};
	public static getAppID = () => {
		return Backend.appID;
	};

	private static sendRequest = async <T extends any>(
		path: string,
		app: boolean,
		data: any,
		method: "get" | "post" | "put" | "delete"
	): Promise<T> => {
		const url = Backend.backendDataServer + Backend.checkPath(path, app);
		let response;
		return new Promise<T>(async (resolve, reject) => {
			switch (method) {
				case "get":
					response = await AXIOS.get(url).catch((ex) => Backend.handleException(ex, reject));
					break;
				case "post":
					response = await AXIOS.post(url, data).catch((ex) => Backend.handleException(ex, reject));
					break;
				case "put":
					response = await AXIOS.put(url, data).catch((ex) => Backend.handleException(ex, reject));
					break;
				case "delete":
					const config: any = { data };
					response = await AXIOS.delete(url, config).catch((ex) => Backend.handleException(ex, reject));
					break;
			}
			if (response) {
				const body = response.data.data;
				resolve(body);
			}
		});
	};

	private static handleException = (ex: any, reject: any) => {
		if (ex.response?.data?.data) {
			reject(ex.response.data.data);
		}
	};

	private static checkPath = (path: string, appIdNececssary: boolean) => {
		if (path.charAt(0) !== "/") {
			path = "/" + path;
		}

		//Get AppId
		if (appIdNececssary && Backend.appID !== undefined) {
			path = "/" + Backend.appID + path;
		}

		return path;
	};

	public static init = () => {
		const setAppID = (state: ReducerType) => {
			if (Backend.getAppID() !== state.vg?.appID) {
				Backend.setAppID(state.vg?.appID);
			}
		};

		setAppID(window.reduxStore?.getState());
		window.reduxStore?.subscribe(() => {
			setAppID(window.reduxStore.getState());
		});
	};
}
