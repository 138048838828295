export default class Action {
	/**
	 * Theme Mode change
	 */
	public static darkLightChange = (mode: "light" | "dark") => {
		window.reduxStore.dispatch({ type: "DARK_LIGHT_CHANGE", mode });
	};

	/**
	 * Select verbandsgemeinde
	 */
	public static selectVG = (vg: Verbandsgemeinde) => {
		window.reduxStore.dispatch({ type: "VERBANDSGEMEINDE", vg });
	};
}
