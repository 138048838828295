import { Dialog } from "onedash-dialog";
import React, { Component } from "react";
import ReactMarkdown from "react-markdown";

interface VGCoronaPoolSelectionProps {
	index: number;
	pools: SwimmingPool[];
	selectedPool: SwimmingPool;
	onPoolChange: (pool: SwimmingPool) => void;
}

class VGCoronaPoolSelection extends Component<VGCoronaPoolSelectionProps> {
	state = {
		poolInfo: undefined as SwimmingPool | undefined,
		showInfo: false,
	};

	showAdditionalInfo = (pool: SwimmingPool) => {
		this.setState({
			poolInfo: pool,
			showInfo: true,
		});
	};

	changePool = (e: any, pool: SwimmingPool) => {
		if (e.target.classList.contains("im-info")) return;
		this.props.onPoolChange(pool);
	};
	render() {
		const { index, pools } = this.props;

		return (
			<div className="pool-selection">
				<h1>{index}. Wählen Sie ...</h1>
				<div className="slider" aria-label="Wählen Sie ...">
					{pools.map((pool, i) => (
						<div
							aria-label={pool.name + " in " + pool.location}
							key={i}
							className={this.props.selectedPool === pool ? "selected pool" : "pool"}
							onClick={(e) => this.changePool(e, pool)}>
							{pool.description !== undefined && <i className="im im-info" onClick={() => this.showAdditionalInfo(pool)}></i>}
							<div className="img" style={{ backgroundImage: `url(${pool.img})` }}></div>
							<p className="name">{pool.name}</p>
							<p className="location">{pool.location}</p>
						</div>
					))}
				</div>

				<Dialog isOpen={this.state.showInfo} onClose={() => this.setState({ showInfo: false })} title="Weitere Informationen">
					<ReactMarkdown>{this.state.poolInfo?.description ?? ""}</ReactMarkdown>
				</Dialog>
			</div>
		);
	}
}

export default VGCoronaPoolSelection;
