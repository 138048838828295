import React, { Component } from "react";
import moment from "moment";
import SwipeableContainer from "react-swipeable-views";
import ReactMarkdown from "react-markdown";
import Collapsible from "react-collapsible";
import FullPagePicker from "../../Components/FullPagePicker/FullPagePicker";
import Backend from "../../Utils/Backend/Backend";
import { Dialog } from "onedash-dialog";

interface VGCoronaSlotSelectionProps {
	index: number;
	selectedPool: SwimmingPool;
	selectedTimeslot?: PoolSlot;
	onTimeslotSelection: (timeslot: PoolSlot | undefined) => void;
}

class VGCoronaSlotSelection extends Component<VGCoronaSlotSelectionProps> {
	datePicker = React.createRef<FullPagePicker>();

	state = {
		dayIndex: 0,
		slotDescription: undefined as undefined | string,
		selectedDay: new Date().getTime(),
		daySlots: undefined as undefined | PoolSlot[],
		disabledDays: [] as number[],
		showMoreInfo: false,
	};
	slotClassName = (slot: PoolSlot) => {
		let className = "slot";
		if (slot.options.freeSlots <= 0) className += " full";

		if (this.props.selectedTimeslot?.from === slot.from) {
			className += " selected";
		}
		return className;
	};

	changeDay = (next: boolean) => {
		let dayIndex = this.state.dayIndex;
		if (next && this.props.selectedPool.slots && Object.keys(this.props.selectedPool.slots).length - 1 > dayIndex) {
			dayIndex++;
		} else if (!next && dayIndex > 0) {
			dayIndex--;
		}
		this.setState({ dayIndex });
	};

	showSlotDescription = (slot: PoolSlot) => {
		this.setState({
			showMoreInfo: true,
			slotDescription: slot.description,
		});
	};
	onTimeslotSelection = (e: any, timeslot: PoolSlot) => {
		if (e.target.classList.contains("im-info")) return;
		this.props.onTimeslotSelection(timeslot);
	};

	private dateChanged = (selectedDay: number) => {
		this.props.onTimeslotSelection(undefined);
		this.setState({ selectedDay }, this.loadDaySlots);
	};

	public resetDayIndex = () => {
		this.setState({
			dayIndex: 0,
		});
	};

	componentDidMount() {
		if (Array.isArray(this.props.selectedPool.slots)) {
			const slots: DayOverview[] = this.props.selectedPool.slots;
			const disabledDays = [];
			let date: moment.Moment;
			const endDate = moment(slots[slots.length - 1].date);
			for (date = moment(slots[0].date); date.isBefore(endDate); date.add(1, "day")) {
				const slot = slots.find((x) => x.date === date.toDate().getTime());
				if (!slot || (slot && slot.isFree === false)) {
					disabledDays.push(date.toDate().getTime());
				}
			}
			this.setState({
				disabledDays,
			});
			this.loadDaySlots();
		}
	}

	loadDaySlots = () => {
		Backend.get<PoolData>(`/vg/corona?day=${this.state.selectedDay}`).then((res) => {
			const pool = res.pools.find((x) => x.id === this.props.selectedPool.id);
			if (pool && Object.keys(pool?.slots).length === 1) {
				const daySlots = pool.slots[Object.keys(pool.slots)[0]];
				this.setState({
					daySlots,
				});
			}
		});
	};
	render() {
		const { index, selectedPool } = this.props;
		const { daySlots, selectedDay, disabledDays } = this.state;
		const slots = selectedPool.slots;
		return (
			<>
				<div className="slot-selection">
					<h1>{index}. Wählen Sie den Zeitraum</h1>
					{!Array.isArray(slots) && Object.keys(slots).length < 10 && (
						<>
							<SwipeableContainer
								onChangeIndex={(dayIndex) => this.setState({ dayIndex })}
								index={this.state.dayIndex}
								className="swipeable-container">
								{Object.keys(slots).map((dayName, i) => (
									<div className="day" key={i}>
										<div className="date-overview">
											<i className="im im-angle-left" onClick={() => this.changeDay(false)}></i>
											<p className="date">{moment(slots[dayName][0].from).format("dddd, DD.MM.YYYY")}</p>
											<i className="im im-angle-right" onClick={() => this.changeDay(true)}></i>
										</div>

										<div className="slots">
											{slots[dayName].length <= 10 &&
												slots[dayName].map((slot, key) => (
													<TimeSlot
														key={key}
														slot={slot}
														onTimeslotSelection={this.onTimeslotSelection}
														showSlotDescription={this.showSlotDescription}
														slotClassName={this.slotClassName}
													/>
												))}

											{slots[dayName].length > 10 && (
												<>
													<Collapsible transitionTime={200} open={false} trigger="Morgens">
														{slots[dayName]
															.filter((x) => moment(x.from).get("hour") < 12)
															.map((slot, key) => (
																<TimeSlot
																	key={key}
																	slot={slot}
																	onTimeslotSelection={this.onTimeslotSelection}
																	showSlotDescription={this.showSlotDescription}
																	slotClassName={this.slotClassName}
																/>
															))}
													</Collapsible>
													<Collapsible transitionTime={200} open={false} trigger="Mittags">
														{slots[dayName]
															.filter(
																(x) => moment(x.from).get("hour") >= 12 && moment(x.from).get("hour") < 17
															)
															.map((slot, key) => (
																<TimeSlot
																	key={key}
																	slot={slot}
																	onTimeslotSelection={this.onTimeslotSelection}
																	showSlotDescription={this.showSlotDescription}
																	slotClassName={this.slotClassName}
																/>
															))}
													</Collapsible>
													<Collapsible transitionTime={200} open={false} trigger="Abends">
														{slots[dayName]
															.filter((x) => moment(x.from).get("hour") >= 17)
															.map((slot, key) => (
																<TimeSlot
																	key={key}
																	slot={slot}
																	onTimeslotSelection={this.onTimeslotSelection}
																	showSlotDescription={this.showSlotDescription}
																	slotClassName={this.slotClassName}
																/>
															))}
													</Collapsible>
												</>
											)}
										</div>
									</div>
								))}
							</SwipeableContainer>

							<div className="day-indicator">
								{Object.keys(selectedPool.slots).map((_x, i) => (
									<span
										key={i}
										onClick={() => this.setState({ dayIndex: i })}
										className={this.state.dayIndex === i ? "active" : ""}
									/>
								))}
							</div>
						</>
					)}

					{Array.isArray(slots) && (
						<div className="day" style={{ padding: 0 }}>
							<button className="primary-btn" onClick={() => this.datePicker.current?.show()}>
								{moment(selectedDay).format("LL")}
								<span className="small"> Klicken zum Datum auswählen</span>
							</button>
							<div className="slots">
								{daySlots &&
									daySlots.length <= 10 &&
									daySlots.map((slot, key) => (
										<TimeSlot
											key={key}
											slot={slot}
											onTimeslotSelection={this.onTimeslotSelection}
											showSlotDescription={this.showSlotDescription}
											slotClassName={this.slotClassName}
										/>
									))}

								{daySlots && daySlots.length > 10 && (
									<>
										<Collapsible transitionTime={200} open={false} trigger="Morgens">
											{daySlots
												.filter((x) => moment(x.from).get("hour") < 12)
												.map((slot, key) => (
													<TimeSlot
														key={key}
														slot={slot}
														onTimeslotSelection={this.onTimeslotSelection}
														showSlotDescription={this.showSlotDescription}
														slotClassName={this.slotClassName}
													/>
												))}
										</Collapsible>
										<Collapsible transitionTime={200} open={false} trigger="Mittags">
											{daySlots
												.filter((x) => moment(x.from).get("hour") >= 12 && moment(x.from).get("hour") < 17)
												.map((slot, key) => (
													<TimeSlot
														key={key}
														slot={slot}
														onTimeslotSelection={this.onTimeslotSelection}
														showSlotDescription={this.showSlotDescription}
														slotClassName={this.slotClassName}
													/>
												))}
										</Collapsible>
										<Collapsible transitionTime={200} open={false} trigger="Abends">
											{daySlots
												.filter((x) => moment(x.from).get("hour") >= 17)
												.map((slot, key) => (
													<TimeSlot
														key={key}
														slot={slot}
														onTimeslotSelection={this.onTimeslotSelection}
														showSlotDescription={this.showSlotDescription}
														slotClassName={this.slotClassName}
													/>
												))}
										</Collapsible>
									</>
								)}
							</div>
						</div>
					)}

					<Dialog
						isOpen={this.state.showMoreInfo}
						onClose={() => this.setState({ showMoreInfo: false })}
						title="Weitere Informationen">
						<ReactMarkdown>{this.state.slotDescription ?? ""}</ReactMarkdown>
					</Dialog>
				</div>

				{Array.isArray(slots) && (
					<FullPagePicker
						selectedStartDate={selectedDay}
						startDate={slots[0].date}
						endDate={slots[slots.length - 1].date}
						onDateChanged={this.dateChanged}
						ref={this.datePicker}
						disabledDays={disabledDays}
					/>
				)}
			</>
		);
	}
}

interface TimeSlotProps {
	slot: PoolSlot;
	onTimeslotSelection: (e: any, slot: PoolSlot) => void;
	slotClassName: (slot: PoolSlot) => string;
	showSlotDescription: (slot: PoolSlot) => void;
}
const TimeSlot = ({ onTimeslotSelection, slot, slotClassName, showSlotDescription }: TimeSlotProps) => (
	<div onClick={(e) => onTimeslotSelection(e, slot)} className={slotClassName(slot)}>
		<div className="checkmark">
			<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
				<circle
					className="path circle"
					fill="none"
					stroke="white"
					strokeWidth="6"
					strokeMiterlimit="10"
					cx="65.1"
					cy="65.1"
					r="62.1"
				/>
				<polyline
					className="path check"
					fill="none"
					stroke="white"
					strokeWidth="6"
					strokeLinecap="round"
					strokeMiterlimit="10"
					points="100.2,40.2 51.5,88.8 29.8,67.5 "
				/>
			</svg>
		</div>
		<div className="slot-data">
			<div>
				<p>
					{moment(slot.from).format("HH:mm")} - {moment(slot.to).format("HH:mm")}
				</p>

				{slot.description && slot.description.length <= 150 && <p className="description">{slot.description}</p>}

				{slot.options.freeSlots > 0 && (
					<p>
						Noch {slot.options.freeSlots} {slot.options.freeSlots > 1 ? "Plätze" : "Platz"} frei
					</p>
				)}
				{slot.options.freeSlots <= 0 && <p>Es sind leider keine Plätze mehr frei</p>}
			</div>
			{slot.description && (
				<div>{slot.description.length > 150 && <i onClick={() => showSlotDescription(slot)} className="im im-info"></i>}</div>
			)}
		</div>
	</div>
);
export default VGCoronaSlotSelection;
