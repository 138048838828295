import React from "react";
import "./VGTicketOverview.sass";
import Header from "../../Components/Header";
import QRCode from "qrcode.react";
import moment from "moment";
import Backend from "../../Utils/Backend/Backend";
import { usePrompt } from "onedash-dialog";

const VGTicketOverview = () => {
	const [tickets, updateTickets] = React.useState([] as Ticket[]);
	const { yesNoPrompt } = usePrompt();

	React.useEffect(() => {
		const ticketString = localStorage.getItem("tickets");
		if (ticketString) {
			let tickets: Ticket[] = JSON.parse(ticketString);
			tickets = tickets.sort((x) => x.to - x.from);
			tickets = tickets.filter((x) => x.to + 1000 * 60 * 15 > new Date().getTime());
			localStorage.setItem("tickets", JSON.stringify(tickets));
			updateTickets(tickets);
		}
	}, []);

	const storno = async (ticket: Ticket) => {
		const ticketString = localStorage.getItem("tickets");
		if (!ticketString) return;
		const yes = await yesNoPrompt("Reservierung stornieren?", "Wollen Sie Ihre Reservierung wirklich stornieren?");
		if (!yes) return;

		let tickets: Ticket[] = JSON.parse(ticketString);
		tickets = tickets.filter((x) => x.ticketID !== ticket.ticketID);
		localStorage.setItem("tickets", JSON.stringify(tickets));
		updateTickets(tickets);

		// Do not wait, e.g. already stornoed ticket will return a error.
		Backend.get(`/tickets/storno/${ticket.ticketID}`);
	};

	return (
		<>
			<div className="ticket-overview app">
				<Header title="Ihre Tickets" subtitle="Übersicht über Ihre bereits reservierten Tickets" bgImage="/ticket.jpg" />
				{tickets.length > 0 && (
					<div className="info">
						<i className={`im im-info`}></i>
						<div className="text">
							Bitte beachten Sie, dass Tickets, welche Sie über den Link in der E-Mail storniert haben hier trotzdem gelistet
							werden.
						</div>
					</div>
				)}
				<div className="ticket-wrapper">
					<div className="tickets">
						{tickets.length === 0 && (
							<div className="info">
								<i className={`im im-info`}></i>
								<div className="text">Sie haben aktuell noch keine Tickets in dieser Verbandsgemeinde reserviert. </div>
							</div>
						)}

						{tickets.map((ticket, i) => (
							<div key={i} className="ticket">
								<div className="ticket-info">
									<h1>
										<span className="day">{moment(ticket.from).format("DD")}</span>
										<span className="month">{moment(ticket.from).format("MMM")}</span>
									</h1>
									<h2>
										{moment(ticket.from).format("HH:mm")} - {moment(ticket.to).format("HH:mm")}
									</h2>
									<h3>
										{ticket.personNum} {ticket.personNum > 1 ? "Personen" : "Person"}
									</h3>
									<h4>{ticket.facilityName}</h4>
								</div>
								<div className="qr">
									<QRCode size={130} value={ticket.ticketID} />
								</div>
								<div className="storno" onClick={() => storno(ticket)}>
									Stornieren
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default VGTicketOverview;
