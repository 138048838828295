import React, { Component } from "react";
import "./VGCorona.sass";
import VGCoronaHeader from "./VGCoronaHeader";
import Backend from "../../Utils/Backend/Backend";
import VGCoronaPoolSelection from "./VGCoronaPoolSelection";
import VGCoronaSlotSelection from "./VGCoronaSlotSelection";
import VGCoronaDataEntry from "./VGCoronaDataEntry";
import PrivacyText from "../../Components/PrivacyText";
import SchutzHygieneregeln from "../../Components/SchutzHygieneregeln";
import AnimationComponent from "../../Components/AnimationComponent";
import * as LoadingAnimation from "../../Resources/Animations/loading.json";
import * as ErrorAnimation from "../../Resources/Animations/error.json";
import * as SuccessAnimation from "../../Resources/Animations/success.json";
import BrowserHistory from "../../Utils/BrowserHistory";
import { Link } from "react-router-dom";
import { Form, Toggle } from "onedash-react-input-form";
import { Dialog, DialogUtils } from "onedash-dialog";

interface VGCoronaProps {}

const ERR_MESSAGES = {
	selectTimeSlot: "Wählen Sie einen Zeitraum aus, an dem Sie kommen möchten.",
	wrongDataEntry: "Bitte füllen Sie alle Felder für die gewählten Haushalte aus.",
	selectToggle: "Akzeptieren Sie, dass Sie die oben genannten Punkte gelesen und verstanden haben.",
};
class VGCorona extends Component<VGCoronaProps> {
	dataEntry = React.createRef<VGCoronaDataEntry>();
	returnIntervall: undefined | number = undefined;
	slotSelection = React.createRef<VGCoronaSlotSelection>();
	state = {
		poolData: undefined as undefined | PoolData,
		selectedPool: undefined as undefined | SwimmingPool,
		selectedTimeSlot: undefined as undefined | PoolSlot,
		globalValid: false,
		submitDialogIsOpen: false,
		valid: {
			dataEntry: {
				state: false,
				errMessage: ERR_MESSAGES.wrongDataEntry,
			},
			slotSelection: {
				state: false,
				errMessage: ERR_MESSAGES.selectTimeSlot,
			},
			submitToggles: {
				state: false,
				errMessage: ERR_MESSAGES.selectToggle,
			},
		},
		errMessage: ERR_MESSAGES.selectTimeSlot,
		response: undefined as undefined | { type: "S" | "E"; message: string; walletUrl?: string },

		returnTimeout: 7,
	};

	componentDidMount = () => {
		this.loadPools();
	};

	loadPools = async () => {
		const poolData = await Backend.get<PoolData>(`/vg/corona/`);
		if (poolData?.pools.length > 0) {
			this.setState({ poolData }, () => this.selectSwimmingPool(poolData.pools[0]));
		} else {
			console.error("No pool data found for selected facility!");
		}
	};

	selectSwimmingPool = (pool: SwimmingPool) => {
		const poolData = this.state.poolData;
		if (poolData) this.setState({ selectedPool: pool, selectedTimeSlot: undefined }, () => this.validate("slotSelection", false));
	};

	selectTimeslot = (selectedTimeSlot: PoolSlot | undefined) => {
		this.setState({
			selectedTimeSlot,
		});

		this.validate("slotSelection", selectedTimeSlot !== undefined ? true : false);
	};

	selectPool = (selectedPool: SwimmingPool) => {
		if (this.slotSelection.current) {
			this.slotSelection.current.resetDayIndex();
		}
		this.setState({
			selectedPool,
			selectedTimeSlot: undefined,
		});
	};

	submitData = () => {
		const { selectedPool, selectedTimeSlot } = this.state;
		if (!selectedPool || !selectedTimeSlot) return;
		// Get Data entry data
		if (!this.dataEntry.current) return;
		this.setState({ submitDialogIsOpen: true });

		const households = this.dataEntry.current.getHouseholds();

		const data = {
			personalData: {},
			values: {
				poolID: selectedPool.id,
				timeSlot: selectedTimeSlot.from,
				households,
			},
		};
		let people = 0;
		households.forEach((household) => {
			people++;
			household?.members.forEach(() => {
				people++;
			});
		});

		Backend.post<any>(`/form/submit/swimTicket`, data)
			.then((data) => {
				this.setState({
					response: {
						type: "S",
						message: "Vielen Dank für Ihre Reservierung. Sie werden in kürze zu Ihrem Ticket weitergeleitet.",
						walletUrl: data.walletUrl,
					},
				});

				// Safe ticket in localstorage
				const ticket: Ticket = {
					from: selectedTimeSlot.from,
					to: selectedTimeSlot.to,
					facilityName: selectedPool.name,
					personNum: people,
					ticketID: data.ticketId,
				};
				const ticketString = localStorage.getItem("tickets");
				if (ticketString) {
					const tickets = JSON.parse(ticketString);
					tickets.push(ticket);
					localStorage.setItem("tickets", JSON.stringify(tickets));
				} else {
					localStorage.setItem("tickets", JSON.stringify([ticket]));
				}
			})
			.catch((err) => {
				this.setState({
					response: {
						type: "E",
						message: err,
					},
				});
			})
			.finally(() => {
				this.returnIntervall = setInterval(() => {
					let returnTimeout = this.state.returnTimeout;
					returnTimeout--;

					if (returnTimeout === 0) {
						clearInterval(this.returnIntervall);
						if (this.state.response?.type === "E") {
							window.location.reload();
						} else {
							DialogUtils.clearAllBodyScrollLocks();
							BrowserHistory.push("/corona-tickets");
						}

						return;
					}
					this.setState({
						returnTimeout,
					});
				}, 1000) as any;
			});
	};

	validate = (component: "dataEntry" | "slotSelection" | "submitToggles", v: boolean) => {
		const valid = this.state.valid;
		valid[component].state = v;
		let globalValid = true;
		Object.keys(valid).forEach((key) => {
			if (globalValid && !(valid as any)[key].state) {
				this.setState({ errMessage: (valid as any)[key].errMessage });
				globalValid = false;
			}
		});

		this.setState({ valid, globalValid });
	};

	render() {
		const { poolData, selectedPool, selectedTimeSlot, globalValid } = this.state;
		const offset = poolData?.pools && poolData?.pools.length > 1 ? 0 : -1;
		return (
			<div className="vg-corona app">
				<VGCoronaHeader selectedPool={selectedPool} />

				{localStorage.getItem("tickets") && JSON.parse(localStorage.getItem("tickets") ?? "[]").length > 0 && (
					<div className="warning">
						<i className={`im im-warning`}></i>
						<div className="text">
							Ihre bereits reservierten Tickets können sie <Link to="/corona-tickets">hier</Link> finden.
						</div>
					</div>
				)}
				{poolData?.additionalTexts && poolData.additionalTexts.length > 0 && (
					<div className="additional-texts">
						{poolData.additionalTexts.map((texts, index) => (
							<div key={index} className={texts.type}>
								<i className={`im im-${texts.type}`}></i>
								<div className="text">{texts.text}</div>
							</div>
						))}
					</div>
				)}
				{poolData && selectedPool && (
					<>
						{offset !== -1 && (
							<VGCoronaPoolSelection
								index={1 + offset}
								pools={poolData?.pools}
								selectedPool={selectedPool}
								onPoolChange={this.selectPool}
							/>
						)}
						{Object.keys(selectedPool.slots).length > 0 && (
							<>
								<VGCoronaSlotSelection
									selectedTimeslot={selectedTimeSlot}
									onTimeslotSelection={this.selectTimeslot}
									index={2 + offset}
									selectedPool={selectedPool}
									ref={this.slotSelection}
								/>

								<VGCoronaDataEntry
									onChange={(valid) => this.validate("dataEntry", valid)}
									ref={this.dataEntry}
									maxPeople={selectedPool.maxBookingPersons ?? poolData.maxBookingPersons}
									index={3 + offset}
								/>
								<div className="submit-area">
									<h1>{4 + offset}. Reservierungsbestätigung</h1>
									<Form onChange={(_v, _f, valid) => this.validate("submitToggles", valid)}>
										<Toggle name="privacy-read" required className="onedash-switch-container">
											<PrivacyText />
										</Toggle>
										<Toggle name="schutz-hygiene-read" required className="onedash-switch-container">
											<SchutzHygieneregeln poolData={poolData} selectedPool={selectedPool} />
										</Toggle>
									</Form>

									<div className={globalValid ? "error" : "error visible"}>
										<i className="im im-warning"></i>
										<div className="text">{this.state.errMessage}</div>
									</div>

									<button className="primary-btn submit-btn" disabled={!globalValid} onClick={this.submitData}>
										Reservieren
									</button>
								</div>
							</>
						)}
						{Object.keys(selectedPool.slots).length === 0 && (
							<div className="error visible">
								<i className="im im-warning"></i>
								<div className="text">Leider ist aktuell keine Reservierung für diese Einrichtung möglich.</div>
							</div>
						)}
					</>
				)}

				<Dialog className="submit-popup" disableEsc disableWrapperClick isOpen={this.state.submitDialogIsOpen}>
					{!this.state.response && (
						<>
							<AnimationComponent width={200} height={200} animationData={LoadingAnimation} />
							<h1>Bitte warten ...</h1>
						</>
					)}
					{this.state.response && this.state.response.type === "S" && (
						<>
							<AnimationComponent options={{ loop: false }} width={200} height={200} animationData={SuccessAnimation} />
							<h1>{this.state.response.message}</h1>
							{this.state.response.walletUrl && (
								<a className="wallet-link" href={this.state.response.walletUrl}>
									<img alt="Zu Apple Wallet hinzufügen" src="https://static.onedash.de/default/apple-wallet.png" />
								</a>
							)}
							<p>Sie werden in {this.state.returnTimeout}s zu Ihren Tickets weitergeleitet</p>
						</>
					)}
					{this.state.response && this.state.response.type === "E" && (
						<>
							<AnimationComponent options={{ loop: false }} width={200} height={200} animationData={ErrorAnimation} />
							<h1>{this.state.response.message}</h1>
							<p>Sie kehren in {this.state.returnTimeout}s zur Startseite zurück</p>
						</>
					)}
				</Dialog>
			</div>
		);
	}
}

export default VGCorona;
