import React, { Component } from "react";
interface VGCoronaHeaderProps {
	bgImage: string;
	title?: string;
	subtitle?: string;
}

class Header extends Component<VGCoronaHeaderProps> {
	render() {
		const { bgImage, title, subtitle } = this.props;
		return (
			<div className="header" style={{ backgroundImage: `url(${bgImage})` }}>
				<>
					<div className="content">
						<h1 className="title">{title}</h1>
						<h2 className="subtitle">{subtitle}</h2>
					</div>
					<div className="overlay"></div>
				</>
			</div>
		);
	}
}

export default Header;
