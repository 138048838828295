const VG_LIST: Verbandsgemeinde[] = [
	{
		name: "Wittlich - Land",
		appID: "0000004563",
		subdomain: "vg-wittlich-land",
		default: true,
	},
	{
		name: "Daun",
		appID: "0000005453",
		subdomain: "vg-daun",
	},
	{
		name: "Bitburger Land",
		appID: "0000007183",
		subdomain: "vg-bitburger-land",
	},
	{
		name: "Thalfang",
		appID: "0000006172",
		subdomain: "vg-thalfang",
	},
	{
		name: "Nahe-Glan",
		appID: "0000002473",
		subdomain: "vg-nahe-glan",
	},
];

export default VG_LIST;
