import BrowserHistory from "./BrowserHistory";

export default class BrowserUtils {
	static getParam = (parameterName: string): undefined | string => {
		let result: undefined | string = undefined;
		let tmp = [];
		window.location.search
			.substr(1)
			.split("&")
			.forEach(function (item) {
				tmp = item.split("=");
				if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
			});
		return result;
	};
	static addParam = (key: string, value: string) => {
		key = encodeURI(key);
		value = encodeURI(value);
		if (BrowserUtils.getParam(key) !== undefined) {
			// No duplicate keys
			BrowserUtils.removeParam(key);
		}

		let searchString = document.location.search;

		let sign = "&";
		if (searchString.indexOf("?") === -1) {
			sign = "?";
		}

		searchString += `${sign}${key}=${value}`;

		const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + searchString;
		window.history.pushState({ path: newurl }, "", newurl);
	};
	static removeParam = (key: string) => {
		key = encodeURI(key);
		let searchString = document.location.search;
		const index = searchString.search(new RegExp(`(\\?|&)${key}=`, "i"));
		if (index !== -1) {
			const end = searchString.search(/(&|\/)/);
			if (end === -1) {
				searchString = searchString.substr(0, index);
			} else {
				searchString = searchString.substr(0, index) + searchString.substr(end);
			}

			const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + searchString;

			window.history.pushState({ path: newurl }, "", newurl);
		}
	};

	static push = (to: string) => {
		BrowserHistory.push(to);
	};
	static append = (to: string, preventDuplicates = true) => {
		let path = window.location.pathname;
		let toPath = to;
		if (path.charAt(path.length - 1) !== "/") {
			path += "/";
		}
		if (to.charAt(0) === "/") {
			toPath = toPath.substr(1, toPath.length - 1);
		}
		if (preventDuplicates === true && window.location.pathname.indexOf(toPath) !== -1) {
			return;
		}
		BrowserHistory.push(path + toPath);
	};
}
