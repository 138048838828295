import React, { Component } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "./FullPagePicker.sass";
import { Dialog } from "onedash-dialog";

registerLocale("de", de);

interface FullPagePickerProps {
	allowRange?: boolean;
	startDate?: number;
	endDate?: number;
	selectedStartDate?: number;
	selectedEndDate?: number;
	disabledDays?: number[];

	onDateChanged: (startDate: number, endDate?: number) => void;
	submitText?: string;
}

export default class FullPagePicker extends Component<FullPagePickerProps> {
	firstEntry = true;

	state = {
		date: [undefined, undefined] as [number | undefined, number | undefined],
		isOpen: false,
	};

	public show = () => {
		// Reset properties
		this.firstEntry = true;
		this.setState({
			date: [this.props.selectedStartDate ?? undefined, this.props.selectedEndDate ?? undefined],
			isOpen: true,
		});
	};
	public hide = () => {
		this.setState({
			isOpen: false,
		});
	};

	private checkDayPickerClass = (date: Date) => {
		if (this.props.disabledDays && this.props.disabledDays.includes(date.getTime())) {
			return "day-is-disabled";
		}

		if (this.state.date[0] === date.getTime()) {
			return "selected-start-date";
		}
		return "";
	};

	private dateChanged = (selectedDate: Date) => {
		const date = this.state.date;
		date[this.firstEntry ? 0 : 1] = selectedDate.getTime();
		this.setState(
			{
				date,
			},
			() => {
				if (this.props.allowRange) this.firstEntry = !this.firstEntry;
			}
		);
	};

	private submitDate = () => {
		this.props.onDateChanged(this.state.date[0] as any, this.state.date[1]);
		this.hide();
	};

	render() {
		const { submitText } = this.props;
		const { date, isOpen } = this.state;
		const timestamp = this.state.date[this.firstEntry ? 0 : 1];
		const currentDate = timestamp ? new Date(timestamp) : undefined;

		return (
			<Dialog isOpen={isOpen} className="no-padding full-page-picker" onClose={this.hide}>
				<DatePicker
					locale="de"
					selected={currentDate}
					minDate={this.props.startDate ? new Date(this.props.startDate) : new Date()}
					maxDate={this.props.endDate ? new Date(this.props.endDate) : undefined}
					onChange={this.dateChanged}
					dayClassName={this.checkDayPickerClass}
					inline
				/>

				<div className={`confirm-date-wrapper ${date[0] !== undefined ? "visible" : ""}`}>
					<button className="primary-btn" onClick={this.submitDate}>
						{submitText ?? "Datum bestätigen"}
						<div className="date">
							<span>{moment(date[0]).format("LL")}</span>
							{date[1] && (
								<>
									- <span>${moment(date[1]).format("LL")}</span>
								</>
							)}
						</div>
					</button>
				</div>
			</Dialog>
		);
	}
}
