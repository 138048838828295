import React, { Component } from "react";
interface VGCoronaHeaderProps {
	selectedPool?: SwimmingPool;
}

class VGCoronaHeader extends Component<VGCoronaHeaderProps> {
	render() {
		const pool = this.props.selectedPool;

		return (
			<div className="header" style={{ backgroundImage: `url(${pool?.img})` }}>
				{pool && (
					<>
						<div className="content">
							<h1 className="name">{pool.name}</h1>
							<h2 className="location">{pool.location}</h2>
						</div>
						<div className="overlay"></div>
					</>
				)}
			</div>
		);
	}
}

export default VGCoronaHeader;
